<template>
  <div class="introduction" style="overflow: hidden;min-width: 1900px;">
    <div
      class="introduction_about scaleBanner"
      :style="'background-image: url(' + domList[0].url + ');'"
    >
      <div class="introduction_about_box">
        <div class="introduction_about_name moveTopTitle">
          {{ domList[0].val }}
        </div>
      </div>
      <div class="box">
        <!-- <div class="introduction_about_item">
          <div
            class="introduction_about_domList"
            v-for="(item, index) in domList"
            :key="index"
            @click="onType(item)"
          >
            <div
              class="introduction_about_item_val"
              :class="[DomId == item.id ? 'clsa' : '']"
            >
              {{ item.val }}
            </div>
            <div class="hengang" v-if="DomId == item.id"></div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="center_title">
      <img
        src="../../../static/newImg2/home.png"
        width="16px"
        @click="toPage('/')"
      />
      <span class="center_home"> > {{ domList[0].val }}</span>
    </div>
    <!-- 企业简介 -->
    <div>
      <div class="center moveTopNormal">
        <div>
          <div class="center_titile">{{ companyName1 }}</div>
          <div class="center_con">
            {{ companyDesc }}
          </div>
          <div class="center_bt">
            <div>
              <div
                class="center_Gre"
                id="ZZMJ"
                @click="toPage('/medicinal')"
              ></div>
              <div @click="toPage('/medicinal')">种植面积(亩)</div>
            </div>
            <div>
              <div class="center_Gre" id="GZRY" style="cursor: default"></div>
              <div>工作人员(人)</div>
            </div>
            <div>
              <div
                class="center_Gre"
                id="XSDW"
                @click="toPage('/introduction', { id: 2 })"
                style="cursor: default"
              ></div>
              <div @click="toPage('/introduction', { id: 2 })">
                下属单位(家)
              </div>
            </div>
            <div>
              <div
                class="center_Gre"
                id="QDSJ"
                @click="toPage('/medicinal')"
              ></div>
              <div @click="toPage('/medicinal')">基地数量(个)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 全国网点 -->
    <div class="moveTopNormal" v-if="companyItem.pid == 0">
      <!-- v-if="" -->
      <dot-map :openDom="true" :adminCode="100000"></dot-map>
    </div>
    <div
      class="bj_unit"
      :style="{ marginTop: companyItem.pid != 0 ? '150px' : '' }"
    >
      <div class="bj_font">下属单位</div>
    </div>
    <!-- 下属单位 -->
    <!-- <div class="moveTopNormal">
      <div class="subordinate">
        <img
          class="subordinate_bg1"
          src="../../../static/newImg2/subordinate_bg1.png"
          width="100%"
        />
        <img
          class="subordinate_bg2"
          src="../../../static/newImg2/subordinate_bg2.png"
          width="738px"
        />
        <img
          class="subordinate_bg3"
          src="../../../static/newImg2/subordinate_bg3.png"
          width="450px"
        />
        <div class="subordinate_company">
          <div class="subordinate_company_1 moveTop2" @click="toPage('/')">
            浙江中峪农业发展有限责任公司
          </div>
          <div class="subordinate_company_2">
            <img
              class="subordinate_company_2_1"
              src="../../../static/newImg2/subordinate_left.png"
              width="90px"
            />
            <img
              class="subordinate_company_2_2"
              src="../../../static/newImg2/subordinate_right.png"
              width="90px"
            />
          </div>
          <div class="subordinate_company_3">
            <div
              class="subordinate_company_3_left moveTop2"
              @click="toPage('/ProvincialCompany?companyId=19')"
            >
              @click="toPage('/base?companyId=19&type=0&index=0')"
              浙江中峪道地药材有限公司
            </div>
            <div
              class="subordinate_company_3_right moveTop2"
              @click="toPage('/ProvincialCompany?companyId=49')"
            >
              @click="toPage('/baseOther?companyId=49&type=0&index=1')"
              浙江中峪药风光能源开发有限公司
            </div>
          </div>
          <div class="subordinate_company_4">
            <img
              class=""
              src="../../../static/newImg2/subordinate_left_line.png"
              width="271px"
            />
            <img
              class="subordinate_company_4_right"
              src="../../../static/newImg2/subordinate_rigth_line.png"
              width="206px"
            />
          </div>
          <div class="subordinate_company_5">
            <div class="subordinate_company_5_left">
              子公司正在建设中，敬请期待
            </div>
            <div class="subordinate_company_5_right">
              子公司正在建设中，敬请期待
            </div>
          </div>
          <div class="subordinate_company_6">
            <img
              class=""
              src="../../../static/newImg2/subordinate_left_line1.png"
              width="58px"
            />
          </div>
          <div class="subordinate_company_7">
            <div
              class="subordinate_company_7_left moveTop2"
              @click="toPage('/projectDetail?id=101')"
            >
              中峪道地药材平顶山仿野生白术种植基地
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="moveTopNormal">
      <div class="subordinate">
        <img
          class="subordinate_bg2"
          src="../../../static/newImg2/subordinate_bg2.png"
          width="738px"
        />
        <img
          class="subordinate_bg3"
          src="../../../static/newImg2/subordinate_bg3.png"
          width="450px"
        />
        <div
          style="
            width: 376px;
            height: 80px;
            font-size: 36px;
            background: linear-gradient(180deg, #22cf5e 0%, #199238 100%);
            border-radius: 13px;
            text-align: center;
            line-height: 80px;
            color: #fff;
            position: relative;
            left: 50%;
            transform: translate(-50%, 0);
          "
        >
          中峪农业集团
        </div>
        <img
          src="../../../static/v1.0/大.png"
          alt=""
          style="
            position: relative;
            left: 50%;
            transform: translate(-50%, 25px);
          "
        />
        <!-- z-index: 4; -->
        <div
          style="
            height: 170px;
            width: 1400px;
            margin: 0 auto;
            position: relative;
            overflow: hidden;
          "
        >
          <div class="examples">
            <div style="height: 550px;">
              <div class="bannerBox">
                <div class="swiper-container2 swiper-no-swiping">
                  <div class="swiper-wrapper">
                    <div
                      class="swiper-slide box"
                      v-for="(item, index) in companyListAll"
                      :key="index"
                      @click="swiper_C2(item, index)"
                    >
                      {{ item.companyName }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="../../../static/v1.0/中.png"
          alt=""
          style="
            position: relative;
            left: 50%;
            transform: translate(-50%, 25px);
          "
        />

        <div id="swiperSlide9">
          <!-- z-index: 4; -->
          <div
            style="
              height: 170px;
              width: 1200px;
              margin: 0 auto;
              position: relative;
              overflow: hidden;
            "
          >
            <div class="examples">
              <div style="height: 550px;">
                <div class="bannerBox">
                  <div
                    class="swiper-container3 swiper-no-swiping"
                    id="swiperSlide3"
                  >
                    <div class="swiper-wrapper">
                      <!-- id="swiperSlide3" -->
                      <div
                        class="swiper-slide box"
                        v-for="(item, index) in companyListAllc3"
                        :key="index"
                        @click="swiper_C3(item, index)"
                      >
                        {{ item.companyName }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="../../../static/v1.0/小.png"
            alt=""
            style="
              position: relative;
              left: 50%;
              transform: translate(-50%, 25px);
            "
          />
          <div id="swiperSlide10">
            <!-- z-index: 4; -->
            <div
              style="
                height: 170px;
                width: 1100px;
                margin: 0 auto;
                position: relative;
                overflow: hidden;
              "
            >
              <div class="examples">
                <div style="height: 550px;">
                  <div class="bannerBox">
                    <div
                      class="swiper-container4 swiper-no-swiping"
                      id="swiperSlide4"
                    >
                      <div class="swiper-wrapper">
                        <div
                          style=""
                          class="swiper-slide box size"
                          v-for="(item, index) in companyListAllc4"
                          :key="index"
                          @click="swiper_C4(item, index)"
                        >
                          {{ item.companyName }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 文化理念 -->
    <div class="culture moveTopNormal">
      <div style="margin-top: 65px; position: relative">
        <div class="imgs">
          <div
            class="imgs_item"
            :class="imgIndex == 0 ? ' active' : ''"
            @click="imgIndex = 0"
            data-index="0"
            @mouseover="imgIndex = 0"
            :style="
              'background-image: url(' +
                require('../../../static/newImg2/img99.jpg') +
                ');background-repeat: no-repeat;background-size: cover;'
            "
            alt=""
          ></div>
          <div
            class="imgs_item"
            :class="imgIndex == 1 ? ' active' : ''"
            @click="imgIndex = 1"
            data-index="1"
            @mouseover="imgIndex = 1"
            :style="
              'background-image: url(' +
                require('../../../static/newImg2/img98.jpg') +
                ');background-repeat: no-repeat;background-size: cover;'
            "
            alt=""
          ></div>
          <div
            class="imgs_item"
            :class="imgIndex == 2 ? ' active' : ''"
            @click="imgIndex = 2"
            data-index="2"
            @mouseover="imgIndex = 2"
            :style="
              'background-image: url(' +
                require('../../../static/newImg2/img97.jpg') +
                ');background-repeat: no-repeat;background-size: cover;'
            "
            alt=""
          ></div>
          <div
            class="imgs_item"
            :class="imgIndex == 3 ? ' active' : ''"
            @click="imgIndex = 3"
            data-index="3"
            @mouseover="imgIndex = 3"
            :style="
              'background-image: url(' +
                require('../../../static/newImg2/img96.jpg') +
                ');background-repeat: no-repeat;background-size: cover;'
            "
            alt=""
          ></div>
          <div
            class="imgs_item"
            :class="imgIndex == 4 ? ' active' : ''"
            @click="imgIndex = 4"
            data-index="4"
            @mouseover="imgIndex = 4"
            :style="
              'background-image: url(' +
                require('../../../static/newImg2/img95.jpg') +
                ');background-repeat: no-repeat;background-size: cover;'
            "
            alt=""
          ></div>
        </div>
        <div class="imgs_tips">
          <div
            class="imgs_tips_item"
            :class="imgIndex == 0 ? ' active' : ''"
            @click="imgIndex = 0"
            data-index="0"
            @mouseover="imgIndex = 0"
          >
            <div class="imgs_tips_item_bg" v-show="imgIndex != 0">文化理念</div>
            <div v-show="imgIndex == 0">
              <div class="imgs_tips_item_culture">文化理念</div>
              <br />
              <div class="imgs_tips_item_font">至专至精 财富共赢</div>
            </div>
          </div>
          <div
            class="imgs_tips_item"
            :class="imgIndex == 1 ? ' active' : ''"
            @click="imgIndex = 1"
            data-index="1"
            @mouseover="imgIndex = 1"
          >
            <div class="imgs_tips_item_bg" v-show="imgIndex != 1">合作精神</div>
            <div v-show="imgIndex == 1">
              <div class="imgs_tips_item_culture">合作精神</div>
              <br />
              <div class="imgs_tips_item_font">
                持诚信互利共荣 以远见共谋发展
              </div>
            </div>
          </div>
          <div
            class="imgs_tips_item"
            :class="imgIndex == 2 ? ' active' : ''"
            @click="imgIndex = 2"
            data-index="2"
            @mouseover="imgIndex = 2"
          >
            <div class="imgs_tips_item_bg" v-show="imgIndex != 2">员工理念</div>
            <div v-show="imgIndex == 2">
              <div class="imgs_tips_item_culture">员工理念</div>
              <br />
              <div class="imgs_tips_item_font">专业进取 锲而不舍</div>
            </div>
          </div>
          <div
            class="imgs_tips_item"
            :class="imgIndex == 3 ? ' active' : ''"
            @click="imgIndex = 3"
            data-index="3"
            @mouseover="imgIndex = 3"
          >
            <div class="imgs_tips_item_bg" v-show="imgIndex != 3">
              企业用人观
            </div>
            <div v-show="imgIndex == 3">
              <div class="imgs_tips_item_culture">企业用人观</div>
              <br />
              <div class="imgs_tips_item_font">以德为首 以智为本 以诚为准</div>
            </div>
          </div>
          <div
            class="imgs_tips_item"
            :class="imgIndex == 4 ? ' active' : ''"
            @click="imgIndex = 4"
            data-index="4"
            @mouseover="imgIndex = 4"
          >
            <div class="imgs_tips_item_bg" v-show="imgIndex != 4">员工训词</div>
            <div v-show="imgIndex == 4">
              <div class="imgs_tips_item_culture">员工训词</div>
              <br />
              <div class="imgs_tips_item_font">
                以严谨的态度做事 用感恩的心做人
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="introduction">
      <!-- <div class="introduction_about scaleBanner">
        <div class="introduction_about_box">
          <div class="introduction_about_name moveTopTitle">
            传承经典创新发展<br />想深入了解中峪农业请联系我们
          </div>
        </div>
      </div>
      <div class="center_title">
      <img src="../../static/image/home.png" width="20px" />
      <span class="center_home"> > 联系我们</span>
    </div> -->
      <div class="coontactUs">
        <div class="coontactUs_title moveTopNormal">联系方式</div>
        <div class="coontactUs_list">
          <div class="coontactUs_list_item moveTopNormal">
            <div class="coontactUs_list_item_img">
              <img src="../../../static/newImg2/dizhi.png" height="45px" />
            </div>
            <div class="coontactUs_list_item_title">联系地址</div>
            <div class="coontactUs_list_item_tips">
              浙江省绍兴市柯桥区金柯大道金昌国际金融中心<br />(金柯大道东100米)2楼207号
            </div>
          </div>
          <div class="coontactUs_list_item moveTopNormal">
            <div class="coontactUs_list_item_img">
              <img src="../../../static/newImg2/dianhua.png" height="36px" />
            </div>
            <div class="coontactUs_list_item_title">联系电话</div>
            <div class="coontactUs_list_item_tips">电话：400-185-3168</div>
          </div>
          <div class="coontactUs_list_item moveTopNormal">
            <img src="../../../static/newImg2/qrcode.jpg" height="120px" />
            <div class="coontactUs_list_item_qrcode">官方公众号</div>
          </div>
        </div>
        <img
          class="moveTopNormal"
          src="../../../static/newImg2/contactUs_map.png"
          width="100%"
          style="margin: 66px 0"
        />
      </div>
    </div>
    <div class="font moveTopNormal">
      <div class="font_dom">战略合作伙伴：</div>
      <div style="display: flex">
        <div class="font_div">
          <div
            class="font_for"
            v-for="(item, index) in companyName"
            :key="index"
          >
            <div style="line-height: 122px">
              <img :src="item.url" width="30px" />
            </div>
            <div style="margin: 0 60px 0 10px">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Swiper, Navigation, Pagination, Autoplay } from "swiper";
Swiper.use([Navigation, Pagination, Autoplay]);
import {
  getAccessToken,
  getSignature,
  getStatistic,
  getCompanyList,
  getCompanyDetailById,
} from "@/api/index";
import dotMap from "../map.vue";
export default {
  name: "introduction",
  data() {
    return {
      introduction_bg: require("../../../static/image/introduction_bg1.png"),
      introduction_b1g: require("../../../static/image/bgimg.png"),
      introduction_b2g: require("../../../static/image/位图备份1111.png"),
      list: [],
      domList: [
        {
          id: 0,
          val: "企业简介",
          url: require("../../../static/newImg2/introduction_bg1.png"),
        },
        {
          id: 1,
          val: "全国网点",
          url: require("../../../static/newImg2/introduction_bg2.png"),
        },
        {
          id: 2,
          val: "下属单位",
          url: require("../../../static/newImg2/introduction_bg3.png"),
        },
        {
          id: 3,
          val: "文化理念",
          url: require("../../../static/newImg2/introduction_bg4.png"),
        },
      ],
      DomId: 0,
      DomVal: "企业简介",
      imgIndex: 0,
      companyName: [
        {
          name: "国家电力投资集团",
          url:
            "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-09-18/331a9ccf00f142e0b92d9afcee41f190.png",
        },
        {
          name: "运达能源科技集团股份有限公司",
          url:
            "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-09-18/cc6ab34dac7240f1800f026e30468113.png",
        },
      ],
      recommendList: [],
      companyDesc: "",
      companyName1: "",
      statisticInfo: {},
      companyItem: null,
      companyListAll: [],
      companyListAllc3: [],
      companyListAllc4: [],
      mySwiper2: null,
      mySwiper3: null,
      mySwiper4: null,
      onIndex: 0,
      onIndex1: 0,
      onIndex2: 0,
    };
  },
  components: { dotMap },
  computed: {},
  created() {
    // this.$store
    // this.$store.commit("setCompany", item);
    this.companyList();
    this.companyItem = this.$store.getters.getCompany;
  },
  mounted() {
    const _this = this;
    const company = this.$store.getters.getCompany;
    if (company) {
      this.companyDesc = company.companyDesc;
      this.companyName1 = company.companyName;
    }
    if (this.$route.query && this.$route.query.id == 2) {
      setTimeout(() => {
        window.scroll({
          top: 2200,
        });
      }, 100);
    } else {
      setTimeout(() => {
        window.scroll(0, 0);
      }, 100);
    }
    if (this.$route.query.id) {
      this.DomId = this.$route.query.id;
    }
    this.getWXInfo();
    this.getStatistic(company.companyId);
    setTimeout(() => {
      _this.mySwiper2 = new Swiper(".swiper-container2", {
        direction: "horizontal", //滑动方向，可设置水平(horizontal)或垂直(vertical)。
        slidesPerView: "auto", // 设置slider容器能够同时显示的slides数量(carousel模式)。类型：number or auto
        centeredSlides: true, // 设定为true时，active slide会居中，而不是默认状态下的居左。
        spaceBetween: 40, // 在slide之间设置距离（单位px）。
        on: {
          slideChangeTransitionEnd: function(e) {},
          click(e) {
            if (_this.companyListAll[e.realIndex].companyId == -1) {
              return;
            }
          },
        },
      });
      _this.mySwiper3 = new Swiper(".swiper-container3", {
        direction: "horizontal", //滑动方向，可设置水平(horizontal)或垂直(vertical)。
        slidesPerView: 3, // 设置slider容器能够同时显示的slides数量(carousel模式)。类型：number or auto
        centeredSlides: true, // 设定为true时，active slide会居中，而不是默认状态下的居左。
        spaceBetween: 40, // 在slide之间设置距离（单位px）。
        on: {
          slideChangeTransitionEnd: function(e) {},
          click(e) {
            if (_this.companyListAll[e.realIndex].companyId == -1) {
              return;
            }
          },
        },
      });
      _this.mySwiper4 = new Swiper(".swiper-container4", {
        direction: "horizontal", //滑动方向，可设置水平(horizontal)或垂直(vertical)。
        slidesPerView: 3, // 设置slider容器能够同时显示的slides数量(carousel模式)。类型：number or auto
        centeredSlides: true, // 设定为true时，active slide会居中，而不是默认状态下的居左。
        spaceBetween: 40, // 在slide之间设置距离（单位px）。
        on: {
          slideChangeTransitionEnd: function(e) {},
          click(e) {
            if (_this.companyListAll[e.realIndex].companyId == -1) {
              return;
            }
          },
        },
      });
      if (this.companyItem.companyLevel == 1) {
        _this.mySwiper2.slideTo(1, 500, false);
        _this.mySwiper3.slideTo(1, 500, false);
        _this.mySwiper4.slideTo(1, 500, false);
      }
    }, 500);
  },
  beforeDestroy() {},
  methods: {
    getStatistic(companyId) {
      getStatistic(companyId).then((res) => {
        const data = res.data;
        this.statisticInfo = {
          plantedNumber:
            data[0].plantedNumber +
            data[1].plantedNumber +
            data[2].plantedNumber,
          projectBaseNumber:
            data[0].projectBaseNumber +
            data[1].projectBaseNumber +
            data[2].projectBaseNumber,
          projectPersonnel:
            data[0].projectPersonnel +
            data[1].projectPersonnel +
            data[2].projectPersonnel,
          subordinateUnits:
            data[0].subordinateUnits +
            data[1].subordinateUnits +
            data[2].subordinateUnits,
        };
        this.allCountUp();
      });
    },
    allCountUp() {
      new this.$countUp("ZZMJ", this.statisticInfo.plantedNumber).start();
      new this.$countUp("XSDW", this.statisticInfo.subordinateUnits).start();
      new this.$countUp("GZRY", this.statisticInfo.projectPersonnel).start();
      new this.$countUp("QDSJ", this.statisticInfo.projectBaseNumber).start();
    },
    toPage(path) {
      this.$router.push({ path });
    },
    onType(val) {
      this.DomId = val.id;
      this.DomVal = val.val;
      document.getElementsByClassName("introduction_about")[0].className =
        "introduction_about";
      setTimeout(() => {
        document.getElementsByClassName("introduction_about")[0].className =
          "introduction_about scaleBanner";
      }, 50);
    },
    getWXInfo() {
      getSignature({ pageUrl: window.location.href }).then((res) => {
        const timestamp = res.data.timestamp;
        const nonceStr = res.data.noncestr;
        const signature = res.data.signature;
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: "wxad8dd02ae97d307f", // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature, // 必填，签名
          jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的JS接口列表
        });
        wx.ready(function() {
          //需在用户可能点击分享按钮前就先调用
          const obj = {
            title: "企业介绍", // 分享标题
            desc:
              "浙江中峪农业发展有限责任公司（简称“中峪农业”），秉持以绿水青山就是金山银山的发展理念，传承发展中医药产业为己任的宗旨，以数字化改革和乡村振兴为初心，致力于在农业领域推动创新数字化发展，专注于道地药材从源头孵化与保护产业生态，带动地方就业、加工、仓储物流等，推动地方文旅、科研、产教研学、人才培养等多元化产业发展；同时积极探索药、风、光、零碳、能源产业带的同步推进。在集团内循环产业链赋能下，跨界融合部署，遵循国家政策，紧跟国家战略，以“数字经济新农业 乡村振兴新载体”的创新产业布局，致力于实现乡村产业振兴和多维推动共同富裕，打造数字化新农业的全新产业标杆。 乡村振兴新载体”的创新产业布局，致力于实现乡村产业振兴和多维推动共同富裕，打造数字化新农业的全新产业标杆。", // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl:
              "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-10-09/887292be61b4443db63c1bf6a2960420.png", // 分享图标
          };
          wx.updateAppMessageShareData(obj);
          wx.updateTimelineShareData(obj);
        });
      });
    },
    swiper_C2(e, index) {
      if (e.companyId == -1) {
        return;
      } else {
        this.mySwiper2.slideTo(index, 500, false);
        this.companyListC3(e.companyId);
        let slide = document.getElementById("swiperSlide9");
        if (this.onIndex > index) {
          this.onIndex = index;
          slide.classList.add("animate-left");
          slide.addEventListener("animationend", () => {
            slide.classList.remove("animate-left");
            // slide.classList.remove("animate-R");
          });
        } else {
          this.onIndex = index;
          slide.classList.add("animate-R");
          slide.addEventListener("animationend", () => {
            // slide.classList.remove("animate-left");
            slide.classList.remove("animate-R");
          });
        }
        setTimeout(() => {
          this.mySwiper3.update();
          this.mySwiper4.update();
          this.mySwiper3.slideTo(1, 500, false);
          this.mySwiper4.slideTo(1, 500, false);
        }, 200);
      }
    },
    swiper_C3(e, index) {
      if (e.companyId == -1) {
        return;
      } else {
        this.mySwiper3.slideTo(index, 500, false);
        this.companyListC4(e.companyId);
        let slide = document.getElementById("swiperSlide10");
        if (this.onIndex1 > index) {
          this.onIndex1 = index;
          slide.classList.add("animate-left");
          slide.addEventListener("animationend", () => {
            slide.classList.remove("animate-left");
          });
        } else {
          this.onIndex1 = index;
          slide.classList.add("animate-R");
          slide.addEventListener("animationend", () => {
            slide.classList.remove("animate-R");
          });
        }
        setTimeout(() => {
          this.mySwiper4.update();
          this.mySwiper4.slideTo(1, 500, false);
        }, 200);
      }
    },
    swiper_C4(e, index) {
      if (e.companyId == -1) {
        return;
      } else {
        this.mySwiper4.slideTo(index, 500, false);
      }
    },
    //c2
    async companyList() {
      const data = { current: 1, size: 1000, pid: 3 };
      await getCompanyList(data).then((res) => {
        this.companyListAll = res.data.list;
        let shengID = 0
        if (this.companyItem.companyLevel == 4) {
          getCompanyDetailById(this.companyItem.pid).then((res) => {
            shengID = res.data.pid
            this.companyListAll.forEach((val, index) => {
              if (val.companyId == res.data.pid) {
                setTimeout(() => {
                  this.mySwiper2.slideTo(index, 500, false);
                }, 500);
              }
            });
          });
        }
        if (this.companyItem.companyLevel == 1) {
          this.companyListC3(this.companyListAll[0].companyId);
        } else if (this.companyItem.companyLevel == 2) {
          this.companyListC3(this.companyItem.companyId);
        } else if (this.companyItem.companyLevel == 3) {
          this.companyListC3(this.companyItem.pid);
        } else {
          setTimeout(()=>{
            this.companyListC3(shengID);
          }, 200)
        }
        if (this.companyListAll.length == 1) {
          const data = [
            {
              adCode: "330000",
              companyCategory: 0,
              companyDesc:
                "中峪道地药材属于集团农业版块，遵循国家政策导向，守护道地药材为己任，扎根于新农村建设，推进中药小镇建设，打造百姓致富“良药”，致力于特色药材为核心的多产业体系发展，携手地方政府振兴乡村经济和产业创新发展，推进新农村共同富裕。2023年10月1日上午，新昌沃洲中峪道地药材基地综合文化接待中心建设项目在沃洲东山地隆重举行奠基典礼。出席仪式的有新昌沃洲相关领导、浙江中医药大学专家教授、文教系统负责人和社会各界人士，与中峪集团一起见证这一历史时刻。",
              companyDirector: "赵月萍",
              companyId: -1,
              companyImage:
                "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-01/e62eec3b852341048ff92706849b115b.jpg",
              companyLevel: 2,
              companyLocation: "浙江省",
              companyName: "浙江中峪道地药材有限公司",
              createBy: null,
              createTime: "2024-01-19 13:40:42",
              fullCompanyId: ":3::19:",
              isDelete: false,
              listingStatus: 1,
              lxy: '["120.55","30.29"]',
              pid: 3,
              updateBy: null,
              updateTime: "2024-09-08 14:33:45",
              website: null,
              websiteId: 3,
            },
            {
              adCode: "330000",
              companyCategory: 0,
              companyDesc:
                "中峪道地药材属于集团农业版块，遵循国家政策导向，守护道地药材为己任，扎根于新农村建设，推进中药小镇建设，打造百姓致富“良药”，致力于特色药材为核心的多产业体系发展，携手地方政府振兴乡村经济和产业创新发展，推进新农村共同富裕。2023年10月1日上午，新昌沃洲中峪道地药材基地综合文化接待中心建设项目在沃洲东山地隆重举行奠基典礼。出席仪式的有新昌沃洲相关领导、浙江中医药大学专家教授、文教系统负责人和社会各界人士，与中峪集团一起见证这一历史时刻。",
              companyDirector: "赵月萍",
              companyId: -1,
              companyImage:
                "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-01/e62eec3b852341048ff92706849b115b.jpg",
              companyLevel: 2,
              companyLocation: "浙江省",
              companyName: "浙江中峪道地药材有限公司",
              createBy: null,
              createTime: "2024-01-19 13:40:42",
              fullCompanyId: ":3::19:",
              isDelete: false,
              listingStatus: 1,
              lxy: '["120.55","30.29"]',
              pid: 3,
              updateBy: null,
              updateTime: "2024-09-08 14:33:45",
              website: null,
              websiteId: 3,
            },
          ];
          this.companyListAll.unshift(...data);
        } else if (this.companyListAll.length == 2) {
          const data = {
            adCode: "330000",
            companyCategory: 0,
            companyDesc:
              "中峪道地药材属于集团农业版块，遵循国家政策导向，守护道地药材为己任，扎根于新农村建设，推进中药小镇建设，打造百姓致富“良药”，致力于特色药材为核心的多产业体系发展，携手地方政府振兴乡村经济和产业创新发展，推进新农村共同富裕。2023年10月1日上午，新昌沃洲中峪道地药材基地综合文化接待中心建设项目在沃洲东山地隆重举行奠基典礼。出席仪式的有新昌沃洲相关领导、浙江中医药大学专家教授、文教系统负责人和社会各界人士，与中峪集团一起见证这一历史时刻。",
            companyDirector: "赵月萍",
            companyId: -1,
            companyImage:
              "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-01/e62eec3b852341048ff92706849b115b.jpg",
            companyLevel: 2,
            companyLocation: "浙江省",
            companyName: "子公司正在建设中,敬请期待",
            createBy: null,
            createTime: "2024-01-19 13:40:42",
            fullCompanyId: ":3::19:",
            isDelete: false,
            listingStatus: 1,
            lxy: '["120.55","30.29"]',
            pid: 3,
            updateBy: null,
            updateTime: "2024-09-08 14:33:45",
            website: null,
            websiteId: 3,
          };
          this.companyListAll.unshift(data);
        } else if (this.companyListAll.length == 0) {
          const data = [
            {
              adCode: "330000",
              companyCategory: 0,
              companyDesc:
                "中峪道地药材属于集团农业版块，遵循国家政策导向，守护道地药材为己任，扎根于新农村建设，推进中药小镇建设，打造百姓致富“良药”，致力于特色药材为核心的多产业体系发展，携手地方政府振兴乡村经济和产业创新发展，推进新农村共同富裕。2023年10月1日上午，新昌沃洲中峪道地药材基地综合文化接待中心建设项目在沃洲东山地隆重举行奠基典礼。出席仪式的有新昌沃洲相关领导、浙江中医药大学专家教授、文教系统负责人和社会各界人士，与中峪集团一起见证这一历史时刻。",
              companyDirector: "赵月萍",
              companyId: -1,
              companyImage:
                "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-01/e62eec3b852341048ff92706849b115b.jpg",
              companyLevel: 2,
              companyLocation: "浙江省",
              companyName: "子公司正在建设中,敬请期待",
              createBy: null,
              createTime: "2024-01-19 13:40:42",
              fullCompanyId: ":3::19:",
              isDelete: false,
              listingStatus: 1,
              lxy: '["120.55","30.29"]',
              pid: 3,
              updateBy: null,
              updateTime: "2024-09-08 14:33:45",
              website: null,
              websiteId: 3,
            },
            {
              adCode: "330000",
              companyCategory: 0,
              companyDesc:
                "中峪道地药材属于集团农业版块，遵循国家政策导向，守护道地药材为己任，扎根于新农村建设，推进中药小镇建设，打造百姓致富“良药”，致力于特色药材为核心的多产业体系发展，携手地方政府振兴乡村经济和产业创新发展，推进新农村共同富裕。2023年10月1日上午，新昌沃洲中峪道地药材基地综合文化接待中心建设项目在沃洲东山地隆重举行奠基典礼。出席仪式的有新昌沃洲相关领导、浙江中医药大学专家教授、文教系统负责人和社会各界人士，与中峪集团一起见证这一历史时刻。",
              companyDirector: "赵月萍",
              companyId: -1,
              companyImage:
                "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-01/e62eec3b852341048ff92706849b115b.jpg",
              companyLevel: 2,
              companyLocation: "浙江省",
              companyName: "子公司正在建设中,敬请期待",
              createBy: null,
              createTime: "2024-01-19 13:40:42",
              fullCompanyId: ":3::19:",
              isDelete: false,
              listingStatus: 1,
              lxy: '["120.55","30.29"]',
              pid: 3,
              updateBy: null,
              updateTime: "2024-09-08 14:33:45",
              website: null,
              websiteId: 3,
            },
            {
              adCode: "330000",
              companyCategory: 0,
              companyDesc:
                "中峪道地药材属于集团农业版块，遵循国家政策导向，守护道地药材为己任，扎根于新农村建设，推进中药小镇建设，打造百姓致富“良药”，致力于特色药材为核心的多产业体系发展，携手地方政府振兴乡村经济和产业创新发展，推进新农村共同富裕。2023年10月1日上午，新昌沃洲中峪道地药材基地综合文化接待中心建设项目在沃洲东山地隆重举行奠基典礼。出席仪式的有新昌沃洲相关领导、浙江中医药大学专家教授、文教系统负责人和社会各界人士，与中峪集团一起见证这一历史时刻。",
              companyDirector: "赵月萍",
              companyId: -1,
              companyImage:
                "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-01/e62eec3b852341048ff92706849b115b.jpg",
              companyLevel: 2,
              companyLocation: "浙江省",
              companyName: "子公司正在建设中,敬请期待",
              createBy: null,
              createTime: "2024-01-19 13:40:42",
              fullCompanyId: ":3::19:",
              isDelete: false,
              listingStatus: 1,
              lxy: '["120.55","30.29"]',
              pid: 3,
              updateBy: null,
              updateTime: "2024-09-08 14:33:45",
              website: null,
              websiteId: 3,
            },
          ];
          this.companyListAll = data;
        }
        this.companyListAll.forEach((val, index) => {
          if (this.companyItem.companyLevel == 2) {
            if (val.companyId == this.companyItem.companyId) {
              setTimeout(() => {
                this.mySwiper2.slideTo(index, 500, false);
                this.mySwiper3.slideTo(1, 500, false);
                this.mySwiper4.slideTo(1, 500, false);
              }, 500);
            }
          } else if (this.companyItem.companyLevel == 3) {
            if (val.companyId == this.companyItem.pid) {
              setTimeout(() => {
                this.mySwiper2.slideTo(index, 500, false);
              }, 500);
            }
          }
        });
      });
    },
    //c3
    async companyListC3(e) {
      this.companyListAllc3 = [];
      const data = { current: 1, size: 1000, pid: e };
      await getCompanyList(data).then((res) => {
        this.companyListAllc3 = res.data.list;
        if (this.companyListAllc3.length == 0) {
          const data = [
            {
              adCode: "330000",
              companyCategory: 0,
              companyDesc:
                "中峪道地药材属于集团农业版块，遵循国家政策导向，守护道地药材为己任，扎根于新农村建设，推进中药小镇建设，打造百姓致富“良药”，致力于特色药材为核心的多产业体系发展，携手地方政府振兴乡村经济和产业创新发展，推进新农村共同富裕。2023年10月1日上午，新昌沃洲中峪道地药材基地综合文化接待中心建设项目在沃洲东山地隆重举行奠基典礼。出席仪式的有新昌沃洲相关领导、浙江中医药大学专家教授、文教系统负责人和社会各界人士，与中峪集团一起见证这一历史时刻。",
              companyDirector: "赵月萍",
              companyId: -1,
              companyImage:
                "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-01/e62eec3b852341048ff92706849b115b.jpg",
              companyLevel: 2,
              companyLocation: "浙江省",
              companyName: "子公司正在建设中,敬请期待",
              createBy: null,
              createTime: "2024-01-19 13:40:42",
              fullCompanyId: ":3::19:",
              isDelete: false,
              listingStatus: 1,
              lxy: '["120.55","30.29"]',
              pid: 3,
              updateBy: null,
              updateTime: "2024-09-08 14:33:45",
              website: null,
              websiteId: 3,
            },
            {
              adCode: "330000",
              companyCategory: 0,
              companyDesc:
                "中峪道地药材属于集团农业版块，遵循国家政策导向，守护道地药材为己任，扎根于新农村建设，推进中药小镇建设，打造百姓致富“良药”，致力于特色药材为核心的多产业体系发展，携手地方政府振兴乡村经济和产业创新发展，推进新农村共同富裕。2023年10月1日上午，新昌沃洲中峪道地药材基地综合文化接待中心建设项目在沃洲东山地隆重举行奠基典礼。出席仪式的有新昌沃洲相关领导、浙江中医药大学专家教授、文教系统负责人和社会各界人士，与中峪集团一起见证这一历史时刻。",
              companyDirector: "赵月萍",
              companyId: -1,
              companyImage:
                "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-01/e62eec3b852341048ff92706849b115b.jpg",
              companyLevel: 2,
              companyLocation: "浙江省",
              companyName: "子公司正在建设中,敬请期待",
              createBy: null,
              createTime: "2024-01-19 13:40:42",
              fullCompanyId: ":3::19:",
              isDelete: false,
              listingStatus: 1,
              lxy: '["120.55","30.29"]',
              pid: 3,
              updateBy: null,
              updateTime: "2024-09-08 14:33:45",
              website: null,
              websiteId: 3,
            },
            {
              adCode: "330000",
              companyCategory: 0,
              companyDesc:
                "中峪道地药材属于集团农业版块，遵循国家政策导向，守护道地药材为己任，扎根于新农村建设，推进中药小镇建设，打造百姓致富“良药”，致力于特色药材为核心的多产业体系发展，携手地方政府振兴乡村经济和产业创新发展，推进新农村共同富裕。2023年10月1日上午，新昌沃洲中峪道地药材基地综合文化接待中心建设项目在沃洲东山地隆重举行奠基典礼。出席仪式的有新昌沃洲相关领导、浙江中医药大学专家教授、文教系统负责人和社会各界人士，与中峪集团一起见证这一历史时刻。",
              companyDirector: "赵月萍",
              companyId: -1,
              companyImage:
                "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-01/e62eec3b852341048ff92706849b115b.jpg",
              companyLevel: 2,
              companyLocation: "浙江省",
              companyName: "子公司正在建设中,敬请期待",
              createBy: null,
              createTime: "2024-01-19 13:40:42",
              fullCompanyId: ":3::19:",
              isDelete: false,
              listingStatus: 1,
              lxy: '["120.55","30.29"]',
              pid: 3,
              updateBy: null,
              updateTime: "2024-09-08 14:33:45",
              website: null,
              websiteId: 3,
            },
          ];
          this.companyListAllc4 = data;
        } else {
          this.companyListC4(this.companyListAllc3[0].companyId);
        }
        if (this.companyListAllc3.length == 1) {
          const data = [
            {
              adCode: "330000",
              companyCategory: 0,
              companyDesc:
                "中峪道地药材属于集团农业版块，遵循国家政策导向，守护道地药材为己任，扎根于新农村建设，推进中药小镇建设，打造百姓致富“良药”，致力于特色药材为核心的多产业体系发展，携手地方政府振兴乡村经济和产业创新发展，推进新农村共同富裕。2023年10月1日上午，新昌沃洲中峪道地药材基地综合文化接待中心建设项目在沃洲东山地隆重举行奠基典礼。出席仪式的有新昌沃洲相关领导、浙江中医药大学专家教授、文教系统负责人和社会各界人士，与中峪集团一起见证这一历史时刻。",
              companyDirector: "赵月萍",
              companyId: -1,
              companyImage:
                "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-01/e62eec3b852341048ff92706849b115b.jpg",
              companyLevel: 2,
              companyLocation: "浙江省",
              companyName: "子公司正在建设中,敬请期待",
              createBy: null,
              createTime: "2024-01-19 13:40:42",
              fullCompanyId: ":3::19:",
              isDelete: false,
              listingStatus: 1,
              lxy: '["120.55","30.29"]',
              pid: 3,
              updateBy: null,
              updateTime: "2024-09-08 14:33:45",
              website: null,
              websiteId: 3,
            },
          ];
          this.companyListAllc3.unshift(...data);
          this.companyListAllc3.push({
            adCode: "330000",
            companyCategory: 0,
            companyDesc:
              "中峪道地药材属于集团农业版块，遵循国家政策导向，守护道地药材为己任，扎根于新农村建设，推进中药小镇建设，打造百姓致富“良药”，致力于特色药材为核心的多产业体系发展，携手地方政府振兴乡村经济和产业创新发展，推进新农村共同富裕。2023年10月1日上午，新昌沃洲中峪道地药材基地综合文化接待中心建设项目在沃洲东山地隆重举行奠基典礼。出席仪式的有新昌沃洲相关领导、浙江中医药大学专家教授、文教系统负责人和社会各界人士，与中峪集团一起见证这一历史时刻。",
            companyDirector: "赵月萍",
            companyId: -1,
            companyImage:
              "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-01/e62eec3b852341048ff92706849b115b.jpg",
            companyLevel: 2,
            companyLocation: "浙江省",
            companyName: "子公司正在建设中,敬请期待",
            createBy: null,
            createTime: "2024-01-19 13:40:42",
            fullCompanyId: ":3::19:",
            isDelete: false,
            listingStatus: 1,
            lxy: '["120.55","30.29"]',
            pid: 3,
            updateBy: null,
            updateTime: "2024-09-08 14:33:45",
            website: null,
            websiteId: 3,
          });
        } else if (this.companyListAllc3.length == 2) {
          const data = {
            adCode: "330000",
            companyCategory: 0,
            companyDesc:
              "中峪道地药材属于集团农业版块，遵循国家政策导向，守护道地药材为己任，扎根于新农村建设，推进中药小镇建设，打造百姓致富“良药”，致力于特色药材为核心的多产业体系发展，携手地方政府振兴乡村经济和产业创新发展，推进新农村共同富裕。2023年10月1日上午，新昌沃洲中峪道地药材基地综合文化接待中心建设项目在沃洲东山地隆重举行奠基典礼。出席仪式的有新昌沃洲相关领导、浙江中医药大学专家教授、文教系统负责人和社会各界人士，与中峪集团一起见证这一历史时刻。",
            companyDirector: "赵月萍",
            companyId: -1,
            companyImage:
              "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-01/e62eec3b852341048ff92706849b115b.jpg",
            companyLevel: 2,
            companyLocation: "浙江省",
            companyName: "子公司正在建设中,敬请期待",
            createBy: null,
            createTime: "2024-01-19 13:40:42",
            fullCompanyId: ":3::19:",
            isDelete: false,
            listingStatus: 1,
            lxy: '["120.55","30.29"]',
            pid: 3,
            updateBy: null,
            updateTime: "2024-09-08 14:33:45",
            website: null,
            websiteId: 3,
          };
          this.companyListAllc3.unshift(data);
        } else if (this.companyListAllc3.length == 0) {
          const data = [
            {
              adCode: "330000",
              companyCategory: 0,
              companyDesc:
                "中峪道地药材属于集团农业版块，遵循国家政策导向，守护道地药材为己任，扎根于新农村建设，推进中药小镇建设，打造百姓致富“良药”，致力于特色药材为核心的多产业体系发展，携手地方政府振兴乡村经济和产业创新发展，推进新农村共同富裕。2023年10月1日上午，新昌沃洲中峪道地药材基地综合文化接待中心建设项目在沃洲东山地隆重举行奠基典礼。出席仪式的有新昌沃洲相关领导、浙江中医药大学专家教授、文教系统负责人和社会各界人士，与中峪集团一起见证这一历史时刻。",
              companyDirector: "赵月萍",
              companyId: -1,
              companyImage:
                "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-01/e62eec3b852341048ff92706849b115b.jpg",
              companyLevel: 2,
              companyLocation: "浙江省",
              companyName: "子公司正在建设中,敬请期待",
              createBy: null,
              createTime: "2024-01-19 13:40:42",
              fullCompanyId: ":3::19:",
              isDelete: false,
              listingStatus: 1,
              lxy: '["120.55","30.29"]',
              pid: 3,
              updateBy: null,
              updateTime: "2024-09-08 14:33:45",
              website: null,
              websiteId: 3,
            },
            {
              adCode: "330000",
              companyCategory: 0,
              companyDesc:
                "中峪道地药材属于集团农业版块，遵循国家政策导向，守护道地药材为己任，扎根于新农村建设，推进中药小镇建设，打造百姓致富“良药”，致力于特色药材为核心的多产业体系发展，携手地方政府振兴乡村经济和产业创新发展，推进新农村共同富裕。2023年10月1日上午，新昌沃洲中峪道地药材基地综合文化接待中心建设项目在沃洲东山地隆重举行奠基典礼。出席仪式的有新昌沃洲相关领导、浙江中医药大学专家教授、文教系统负责人和社会各界人士，与中峪集团一起见证这一历史时刻。",
              companyDirector: "赵月萍",
              companyId: -1,
              companyImage:
                "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-01/e62eec3b852341048ff92706849b115b.jpg",
              companyLevel: 2,
              companyLocation: "浙江省",
              companyName: "子公司正在建设中,敬请期待",
              createBy: null,
              createTime: "2024-01-19 13:40:42",
              fullCompanyId: ":3::19:",
              isDelete: false,
              listingStatus: 1,
              lxy: '["120.55","30.29"]',
              pid: 3,
              updateBy: null,
              updateTime: "2024-09-08 14:33:45",
              website: null,
              websiteId: 3,
            },
            {
              adCode: "330000",
              companyCategory: 0,
              companyDesc:
                "中峪道地药材属于集团农业版块，遵循国家政策导向，守护道地药材为己任，扎根于新农村建设，推进中药小镇建设，打造百姓致富“良药”，致力于特色药材为核心的多产业体系发展，携手地方政府振兴乡村经济和产业创新发展，推进新农村共同富裕。2023年10月1日上午，新昌沃洲中峪道地药材基地综合文化接待中心建设项目在沃洲东山地隆重举行奠基典礼。出席仪式的有新昌沃洲相关领导、浙江中医药大学专家教授、文教系统负责人和社会各界人士，与中峪集团一起见证这一历史时刻。",
              companyDirector: "赵月萍",
              companyId: -1,
              companyImage:
                "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-01/e62eec3b852341048ff92706849b115b.jpg",
              companyLevel: 2,
              companyLocation: "浙江省",
              companyName: "子公司正在建设中,敬请期待",
              createBy: null,
              createTime: "2024-01-19 13:40:42",
              fullCompanyId: ":3::19:",
              isDelete: false,
              listingStatus: 1,
              lxy: '["120.55","30.29"]',
              pid: 3,
              updateBy: null,
              updateTime: "2024-09-08 14:33:45",
              website: null,
              websiteId: 3,
            },
          ];
          this.companyListAllc3 = data;
        }
        this.companyListAllc3.forEach((val, index) => {
          if (this.companyItem.companyLevel == 3) {
            if (val.companyId == this.companyItem.companyId) {
              setTimeout(() => {
                this.mySwiper3.slideTo(index, 500, false);
                this.mySwiper4.slideTo(1, 500, false);
              }, 500);
            }
          } else if (this.companyItem.companyLevel == 4) {
            if (val.companyId == this.companyItem.pid) {
              setTimeout(() => {
                this.mySwiper3.slideTo(index, 500, false);
                this.mySwiper4.slideTo(1, 500, false);
              }, 500);
            }
          }
        });
      });
    },
    //c4
    async companyListC4(e) {
      this.companyListAllc4 = [];
      const data = { current: 1, size: 1000, pid: e };
      await getCompanyList(data).then((res) => {
        this.companyListAllc4 = res.data.list;
        if (res.data.list.length == 1) {
          const data = [
            {
              adCode: "330000",
              companyCategory: 0,
              companyDesc:
                "中峪道地药材属于集团农业版块，遵循国家政策导向，守护道地药材为己任，扎根于新农村建设，推进中药小镇建设，打造百姓致富“良药”，致力于特色药材为核心的多产业体系发展，携手地方政府振兴乡村经济和产业创新发展，推进新农村共同富裕。2023年10月1日上午，新昌沃洲中峪道地药材基地综合文化接待中心建设项目在沃洲东山地隆重举行奠基典礼。出席仪式的有新昌沃洲相关领导、浙江中医药大学专家教授、文教系统负责人和社会各界人士，与中峪集团一起见证这一历史时刻。",
              companyDirector: "赵月萍",
              companyId: -1,
              companyImage:
                "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-01/e62eec3b852341048ff92706849b115b.jpg",
              companyLevel: 2,
              companyLocation: "浙江省",
              companyName: "子公司正在建设中,敬请期待",
              createBy: null,
              createTime: "2024-01-19 13:40:42",
              fullCompanyId: ":3::19:",
              isDelete: false,
              listingStatus: 1,
              lxy: '["120.55","30.29"]',
              pid: 3,
              updateBy: null,
              updateTime: "2024-09-08 14:33:45",
              website: null,
              websiteId: 3,
            },
          ];
          this.companyListAllc4.unshift(...data);
          this.companyListAllc4.push({
            adCode: "330000",
            companyCategory: 0,
            companyDesc:
              "中峪道地药材属于集团农业版块，遵循国家政策导向，守护道地药材为己任，扎根于新农村建设，推进中药小镇建设，打造百姓致富“良药”，致力于特色药材为核心的多产业体系发展，携手地方政府振兴乡村经济和产业创新发展，推进新农村共同富裕。2023年10月1日上午，新昌沃洲中峪道地药材基地综合文化接待中心建设项目在沃洲东山地隆重举行奠基典礼。出席仪式的有新昌沃洲相关领导、浙江中医药大学专家教授、文教系统负责人和社会各界人士，与中峪集团一起见证这一历史时刻。",
            companyDirector: "赵月萍",
            companyId: -1,
            companyImage:
              "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-01/e62eec3b852341048ff92706849b115b.jpg",
            companyLevel: 2,
            companyLocation: "浙江省",
            companyName: "子公司正在建设中,敬请期待",
            createBy: null,
            createTime: "2024-01-19 13:40:42",
            fullCompanyId: ":3::19:",
            isDelete: false,
            listingStatus: 1,
            lxy: '["120.55","30.29"]',
            pid: 3,
            updateBy: null,
            updateTime: "2024-09-08 14:33:45",
            website: null,
            websiteId: 3,
          });
        } else if (this.companyListAllc4.length == 2) {
          const data = {
            adCode: "330000",
            companyCategory: 0,
            companyDesc:
              "中峪道地药材属于集团农业版块，遵循国家政策导向，守护道地药材为己任，扎根于新农村建设，推进中药小镇建设，打造百姓致富“良药”，致力于特色药材为核心的多产业体系发展，携手地方政府振兴乡村经济和产业创新发展，推进新农村共同富裕。2023年10月1日上午，新昌沃洲中峪道地药材基地综合文化接待中心建设项目在沃洲东山地隆重举行奠基典礼。出席仪式的有新昌沃洲相关领导、浙江中医药大学专家教授、文教系统负责人和社会各界人士，与中峪集团一起见证这一历史时刻。",
            companyDirector: "赵月萍",
            companyId: -1,
            companyImage:
              "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-01/e62eec3b852341048ff92706849b115b.jpg",
            companyLevel: 2,
            companyLocation: "浙江省",
            companyName: "子公司正在建设中,敬请期待",
            createBy: null,
            createTime: "2024-01-19 13:40:42",
            fullCompanyId: ":3::19:",
            isDelete: false,
            listingStatus: 1,
            lxy: '["120.55","30.29"]',
            pid: 3,
            updateBy: null,
            updateTime: "2024-09-08 14:33:45",
            website: null,
            websiteId: 3,
          };
          this.companyListAllc4.unshift(data);
        } else if (this.companyListAllc4.length == 0) {
          const data = [
            {
              adCode: "330000",
              companyCategory: 0,
              companyDesc:
                "中峪道地药材属于集团农业版块，遵循国家政策导向，守护道地药材为己任，扎根于新农村建设，推进中药小镇建设，打造百姓致富“良药”，致力于特色药材为核心的多产业体系发展，携手地方政府振兴乡村经济和产业创新发展，推进新农村共同富裕。2023年10月1日上午，新昌沃洲中峪道地药材基地综合文化接待中心建设项目在沃洲东山地隆重举行奠基典礼。出席仪式的有新昌沃洲相关领导、浙江中医药大学专家教授、文教系统负责人和社会各界人士，与中峪集团一起见证这一历史时刻。",
              companyDirector: "赵月萍",
              companyId: -1,
              companyImage:
                "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-01/e62eec3b852341048ff92706849b115b.jpg",
              companyLevel: 2,
              companyLocation: "浙江省",
              companyName: "子公司正在建设中,敬请期待",
              createBy: null,
              createTime: "2024-01-19 13:40:42",
              fullCompanyId: ":3::19:",
              isDelete: false,
              listingStatus: 1,
              lxy: '["120.55","30.29"]',
              pid: 3,
              updateBy: null,
              updateTime: "2024-09-08 14:33:45",
              website: null,
              websiteId: 3,
            },
            {
              adCode: "330000",
              companyCategory: 0,
              companyDesc:
                "中峪道地药材属于集团农业版块，遵循国家政策导向，守护道地药材为己任，扎根于新农村建设，推进中药小镇建设，打造百姓致富“良药”，致力于特色药材为核心的多产业体系发展，携手地方政府振兴乡村经济和产业创新发展，推进新农村共同富裕。2023年10月1日上午，新昌沃洲中峪道地药材基地综合文化接待中心建设项目在沃洲东山地隆重举行奠基典礼。出席仪式的有新昌沃洲相关领导、浙江中医药大学专家教授、文教系统负责人和社会各界人士，与中峪集团一起见证这一历史时刻。",
              companyDirector: "赵月萍",
              companyId: -1,
              companyImage:
                "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-01/e62eec3b852341048ff92706849b115b.jpg",
              companyLevel: 2,
              companyLocation: "浙江省",
              companyName: "子公司正在建设中,敬请期待",
              createBy: null,
              createTime: "2024-01-19 13:40:42",
              fullCompanyId: ":3::19:",
              isDelete: false,
              listingStatus: 1,
              lxy: '["120.55","30.29"]',
              pid: 3,
              updateBy: null,
              updateTime: "2024-09-08 14:33:45",
              website: null,
              websiteId: 3,
            },
            {
              adCode: "330000",
              companyCategory: 0,
              companyDesc:
                "中峪道地药材属于集团农业版块，遵循国家政策导向，守护道地药材为己任，扎根于新农村建设，推进中药小镇建设，打造百姓致富“良药”，致力于特色药材为核心的多产业体系发展，携手地方政府振兴乡村经济和产业创新发展，推进新农村共同富裕。2023年10月1日上午，新昌沃洲中峪道地药材基地综合文化接待中心建设项目在沃洲东山地隆重举行奠基典礼。出席仪式的有新昌沃洲相关领导、浙江中医药大学专家教授、文教系统负责人和社会各界人士，与中峪集团一起见证这一历史时刻。",
              companyDirector: "赵月萍",
              companyId: -1,
              companyImage:
                "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-03-01/e62eec3b852341048ff92706849b115b.jpg",
              companyLevel: 2,
              companyLocation: "浙江省",
              companyName: "子公司正在建设中,敬请期待",
              createBy: null,
              createTime: "2024-01-19 13:40:42",
              fullCompanyId: ":3::19:",
              isDelete: false,
              listingStatus: 1,
              lxy: '["120.55","30.29"]',
              pid: 3,
              updateBy: null,
              updateTime: "2024-09-08 14:33:45",
              website: null,
              websiteId: 3,
            },
          ];
          this.companyListAllc4 = data;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.introduction {
  overflow: hidden;
}

.introduction_bg {
  background: #f7f7f7;

  img {
    width: 100%;
  }
}

.introduction_about {
  width: 100%;
  height: 560px;
  background-size: cover;
  background-repeat: no-repeat;

  .introduction_about_bg {
    width: 1280px;
    margin: 0 auto;
    text-align: center;

    .introduction_about_title_zn {
      font-size: 20px;
      color: #1d1b19;
      font-style: normal;
    }

    .introduction_about_title_en {
      font-size: 20px;
      color: rgba(29, 27, 25, 0.44);
      font-style: normal;
    }

    .introduction_about_title_line {
      width: 323px;
      height: 1px;
      background: #efefef;
      margin: 16px auto 50px auto;
    }
  }

  .introduction_about_img {
    display: flex;
    margin: 0 auto;
    justify-content: center;

    img {
      width: 880px;
      height: 440px;
    }
  }

  .introduction_about_box {
    position: relative;
    width: 100%;
    height: 560px;
  }

  .box {
    position: absolute;
    top: 493px;
    left: 50%;
    transform: translateX(-50%);
  }

  .introduction_about_domList {
    width: 89px;
  }

  .introduction_about_item {
    display: flex;
    width: 824px;
    height: 67px;
    justify-content: space-between;

    div {
      // flex: 1;
      text-align: center;
    }

    .clsa {
      color: #fff !important;
      opacity: 1 !important;
      font-weight: bold;
    }

    .introduction_about_item_val {
      color: #fff;
      opacity: 0.5;
      cursor: pointer;
      height: 66px;
      font-size: 18px;
      line-height: 30px;
    }

    .hengang {
      width: 89px;
      height: 4px;
      background: #fff;
      position: absolute;
      bottom: 0;
      // transform: translateX(25%);
    }
  }

  .introduction_about_name {
    font-weight: bold;
    font-size: 56px;
    color: #ffffff;
    text-align: center;
    letter-spacing: 1px;
    font-family: 黑体;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .introduction_about_content {
    width: 1200px;
    margin: 0 auto;
    font-size: 18px;
    font-weight: 400;
    color: #000;
    line-height: 35px;
    letter-spacing: 2px;
    font-family: 宋体;
    text-align: center;
  }

  .introduction_about_detail {
    cursor: pointer;
    padding-bottom: 40px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 21px;
    background: linear-gradient(180deg, #8eb368 0%, #608640 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.introduction_culture {
  width: 100%;
  padding: 100px 0;

  .introduction_culture_bg {
    width: 1240px;
    padding: 0 20px;
    margin: 0 auto;
    text-align: center;

    .introduction_culture_title_zn {
      font-size: 20px;
      color: #1d1b19;
      font-style: normal;
    }

    .introduction_culture_title_en {
      font-size: 20px;
      color: rgba(29, 27, 25, 0.44);
      font-style: normal;
    }

    .introduction_culture_title_line {
      width: 323px;
      height: 1px;
      background: #efefef;
      margin: 16px auto 50px auto;
    }
  }

  .introduction_culture_img {
    display: flex;
    margin: 0 auto;
    width: 1280px;
    justify-content: space-between;

    img {
      width: calc((100% - 36px) / 3);
    }
  }

  .introduction_culture_content {
    width: 1280px;
    margin: 90px auto;
    font-size: 20px;
    font-weight: 400;
    color: #1d1b19;
    line-height: 34px;
    letter-spacing: 1px;
  }

  .introduction_culture_list {
    display: flex;
    width: 1280px;
    margin: 90px auto;
    justify-content: space-between;

    .introduction_culture_list_item {
      width: 200px;
      height: 200px;
      text-align: center;
      border-radius: 50%;
      background: #ffffff;
      box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.04);
      position: relative;

      .introduction_culture_list_item_name {
        margin-top: 55px;
      }

      .introduction_culture_list_item_value {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: 黑体;
        font-size: 24px;
        color: #ffffff;
        letter-spacing: 1px;
        text-align: left;
      }
    }

    .introduction_culture_list_itemRed {
      background-color: #9e1307;
    }
  }
}

.introduction_qualification {
  width: 100%;
  margin: 100px 0;
  background: #f7f7f7;

  .introduction_qualification_bg {
    width: 1280px;
    margin: 0 auto;
    text-align: center;

    .introduction_qualification_title_zn {
      font-size: 20px;
      color: #1d1b19;
      font-style: normal;
    }

    .introduction_qualification_title_en {
      font-size: 20px;
      color: rgba(29, 27, 25, 0.44);
      font-style: normal;
    }

    .introduction_qualification_title_line {
      width: 323px;
      height: 1px;
      background: #efefef;
      margin: 16px auto 90px auto;
    }
  }

  .introduction_qualification_img {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background: #fff;

    .introduction_qualification_img_left {
      background-position: center;
      transition: all 0.3s;
      cursor: pointer;
      margin: 0 25px;
      width: 810px;
      height: 500px;

      .introduction_qualification_img_titile {
        font-weight: 500;
        font-size: 32px;
        color: #ffffff;
        line-height: 48px;
        letter-spacing: 1px;
        height: 32px;
        margin-left: 48px;
        font-style: normal;
        position: absolute;
        bottom: 117px;
      }

      .introduction_qualification_img_tips {
        font-weight: 400;
        width: 663px;
        height: 54px;
        font-size: 16px;
        color: #ffffff;
        line-height: 27px;
        margin-left: 48px;
        font-style: normal;
        position: absolute;
        bottom: 30px;
      }
    }

    .introduction_qualification_img_left:hover {
      background-size: 105% !important;
      background-position: center;
      background-repeat: no-repeat !important;
    }

    .introduction_qualification_img_left_name {
      text-align: center;
      margin: 18px 0;
      width: 180px;
      height: 40px;
      background: #6b766a;
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      line-height: 40px;
      font-family: 宋体;
    }

    .introduction_qualification_img_left_tips {
      font-size: 14px;
      font-weight: 400;
      color: #858585;
      line-height: 23px;
      letter-spacing: 1px;
    }

    .introduction_qualification_img_left_type {
      margin: 18px 0;
      text-align: center;
      width: 64px;
      height: 40px;
      background: #6b766a;
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      line-height: 40px;
      font-family: 宋体;
    }

    .introduction_qualification_img_left_typeName {
      font-size: 14px;
      font-weight: 400;
      color: #858585;
      line-height: 23px;
    }

    .introduction_qualification_img_left_detail {
      cursor: pointer;
      text-align: right;
      margin-top: 40px;
      font-family: 黑体;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 21px;
      background: linear-gradient(180deg, #8eb368 0%, #608640 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    img {
      width: 92%;
      height: 294px;
    }

    div {
      width: 480px;
    }
  }

  .introduction_qualification_phone {
    margin-top: 74px;
    font-size: 16px;
    font-weight: 400;
    color: #1d1b19;
    line-height: 22px;
    display: flex;
    justify-content: center;

    .introduction_qualification_phone_call {
      margin-right: 26px;
    }
  }

  .introduction_qualification_content {
    width: 1280px;
    margin: 90px auto;
    font-size: 20px;
    font-weight: 400;
    color: #1d1b19;
    line-height: 34px;
    letter-spacing: 1px;
  }
}

.center {
  width: 1600px;
  background: url("../../../static/image/位图.png") no-repeat;
  margin: 54px auto 54px auto;
  background-size: contain;
  background-position: center;

  .center_titile {
    margin-top: 70px;
    text-align: center;
    font-weight: bold;
    font-size: 40px;
    color: #202020;
    line-height: 60px;
  }

  .center_con {
    width: 1280px;
    margin: 54px auto 0 auto;
    font-weight: 400;
    font-size: 22px;
    color: #2e2e2e;
    line-height: 40px;
    text-align: left;
    font-style: normal;
  }

  .center_bt {
    width: 80%;
    margin: 0 auto;
    margin-top: 126px;
    display: flex;
    text-align: center;
    justify-content: space-between;

    & > div {
      width: 25%;
    }

    .center_Gre {
      font-weight: bold;
      font-size: 76px;
      color: #1e9409;
      line-height: 91px;
      letter-spacing: 1px;
      text-align: center;
      font-style: normal;
      cursor: pointer;
    }
  }
}

.culture {
  width: 1600px;
  margin: 0 auto 95px auto;

  .imgs {
    width: 100%;
    height: 530px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
  }

  .imgs_item {
    transition: width 0.2s;
    width: 300px;
    cursor: pointer;
  }

  .imgs_tips {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
  }

  .imgs_tips_item {
    text-align: center;
    transition: all 0.2s ease-in-out;
    width: 300px;
    cursor: pointer;
    overflow: hidden;

    .imgs_tips_item_bg {
      //   background-color: rgba(225, 21, 21, 0.8);
      background-color: #157b2f;
      color: #fff;
      margin: 0 auto;
      width: max-content;
      padding: 0 10px;
      line-height: 34px;
      border-radius: 5px;
    }
  }

  .imgs_tips_item_culture {
    font-weight: 900;
    font-size: 32px;
    color: #ffffff;
    line-height: 45px;
    letter-spacing: 5px;
    width: 620px;
  }

  .imgs_tips_item_font {
    font-weight: 500;
    font-size: 22px;
    color: #ffffff;
    line-height: 33px;
    letter-spacing: 8px;
    width: 620px;
  }

  .active {
    width: 620px;
  }
}

.subordinate {
  width: 100%;
  height: 1350px;
  margin: 66px auto 0 auto;
  position: relative;
  overflow: hidden;
  .examples {
    // width: 1100px;
    padding-top: 50px;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    //   background-color: #fff;
    font-size: 14px;

    .bannerBox {
      // height: 550px;
      .box {
        cursor: pointer;
        width: 400px;
        height: 80px;
        line-height: 80px;
        text-align: center;
        background: #ffffff;
        box-shadow: inset 0px 1px 33px 0px rgba(21, 123, 47, 0.2);
        border-radius: 13px;
        font-size: 24px;
        border: 1px solid #b5d6bd;
        opacity: 0.53;
      }
    }

    .mask {
      width: 1400px;
      height: 400px;
      bottom: 0;
      position: absolute;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(8, 69, 33, 0.83) 100%
      );
    }

    // .swiper-wrapper :nth-child(2n) {
    //   background-color: skyblue;
    // }
    // .swiper-wrapper :nth-child(2n + 1) {
    //   background-color: pink;
    // }
    // .swiper-slide:hover {
    //   transform: scale(1.1);
    // }
    .tips {
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: 46px;
      background-color: #b5654d;
      text-align: center;

      img {
        width: 12px;
        height: 14px;
        position: absolute;
        top: 7.5px;
        left: 12vw;
        color: #fff;
      }

      p {
        width: 240px;
        text-align: left;
        color: #fff;
        font-size: 12px;
        position: absolute;
        left: 18vw;
        top: 7px;
        line-height: 16px;
      }
    }
  }
  .subordinate_bg1 {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  .subordinate_bg2 {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }

  .subordinate_bg3 {
    position: absolute;
    bottom: 200px;
    left: 50%;
    transform: translateX(-50%);
    // z-index: 3;
    animation: suspensionMap 3s linear infinite;
  }

  @keyframes suspensionMap {
    0% {
      transform: translateX(-50%) translateY(-40px);
    }

    50% {
      transform: translateX(-50%) translateY(0);
    }

    100% {
      transform: translateX(-50%) translateY(-40px);
    }
  }

  .subordinate_company {
    padding-top: 10px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    // z-index: 4;

    .subordinate_company_1 {
      background: linear-gradient(180deg, #22cf5e 0%, #199238 100%);
      border-radius: 13px;
      line-height: 80px;
      padding: 0 48px;
      font-weight: bold;
      font-size: 20px;
      color: #ffffff;
      text-align: center;
      width: 300px;
      margin: 0 auto;
      cursor: pointer;
    }

    .subordinate_company_2 {
      width: 500px;
      display: flex;
      justify-content: space-between;
      margin: 30px auto;
    }

    .subordinate_company_3 {
      width: 900px;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;

      .subordinate_company_3_left,
      .subordinate_company_3_right {
        width: 400px;
        box-shadow: inset 0px 1px 33px 0px rgba(21, 123, 47, 0.2);
        border-radius: 13px;
        border: 1px solid #b5d6bd;
        font-weight: bold;
        font-size: 20px;
        color: #157b2f;
        line-height: 80px;
        text-align: center;
        cursor: pointer;
      }
    }

    .subordinate_company_4 {
      width: 1030px;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;

      .subordinate_company_4_right {
        margin-right: 50px;
      }
    }

    .subordinate_company_5 {
      width: 1400px;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;

      .subordinate_company_5_left {
        width: 400px;
        box-shadow: inset 0px 1px 33px 0px rgba(173, 173, 173, 0.2);
        border-radius: 13px;
        border: 1px solid #d1d1d1;
        font-weight: bold;
        font-size: 20px;
        color: #000;
        line-height: 80px;
        text-align: center;
        cursor: pointer;
      }

      .subordinate_company_5_right {
        margin-right: 40px;
        width: 400px;
        height: 80px;
        box-shadow: inset 0px 1px 33px 0px rgba(173, 173, 173, 0.2);
        border-radius: 13px;
        border: 1px solid #d1d1d1;
        font-weight: bold;
        font-size: 20px;
        color: #000;
        line-height: 80px;
        text-align: center;
        cursor: pointer;
      }
    }

    .subordinate_company_6 {
      width: 1130px;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
    }

    .subordinate_company_7 {
      width: 1500px;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;

      .subordinate_company_7_left {
        width: 400px;
        box-shadow: inset 0px 1px 33px 0px rgba(21, 123, 47, 0.2);
        border-radius: 13px;
        border: 1px solid #b5d6bd;
        font-weight: bold;
        font-size: 20px;
        color: #157b2f;
        line-height: 80px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}

.center_title {
  width: 1600px;
  margin: 0 auto;
  margin-top: 54px;
  z-index: 9;
  position: relative;

  & > img {
    cursor: pointer;
  }

  .center_home {
    font-weight: 400;
    font-size: 16px;
    color: #1d1b19;
    vertical-align: top;
    cursor: pointer;
  }
}

.bj_unit {
  margin-bottom: 123px;
  width: 100%;
  height: 560px;
  background: url("../../../static/newImg2/introduction_bg3.png");

  .bj_font {
    // height: 560px;
    text-align: center;
    line-height: 560px;
    font-family: 楷体;
    font-weight: 900;
    font-size: 72px;
    color: #ffffff;
    letter-spacing: 10px;
  }
}

.introduction {
  overflow: hidden;

  .introduction_about {
    width: 100%;
    height: 560px;
    background-image: url("../../../static/newImg2/contactUs_bg.png");
    background-repeat: no-repeat;
    background-size: cover;

    .introduction_about_box {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .introduction_about_name {
      font-weight: bold;
      font-size: 50px;
      color: #ffffff;
      letter-spacing: 18px;
      text-align: center;
      letter-spacing: 1px;
      line-height: 80px;
      font-family: 黑体;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .center_title {
    width: 1600px;
    margin: 0 auto;
    margin-top: 54px;

    .center_home {
      font-weight: 400;
      font-size: 16px;
      color: #1d1b19;
      vertical-align: top;
    }
  }

  .coontactUs {
    width: 1600px;
    margin: 0 auto;
    margin-top: 54px;

    .coontactUs_title {
      font-size: 48px;
      text-align: center;
      color: #000000;
      line-height: 54px;
    }

    .coontactUs_list {
      display: flex;
      justify-content: space-between;
      margin-top: 66px;

      .coontactUs_list_item {
        width: 30%;
        height: 240px;
        border: 1px solid rgba(208, 208, 208, 0.66);
        text-align: center;
        padding: 40px 0 0 0;

        & > img {
          border: 1px solid rgba(208, 208, 208, 0.66);
        }

        .coontactUs_list_item_img {
          color: #157b2f;
          font-size: 50px;
        }

        .coontactUs_list_item_title {
          font-size: 32px;
          color: #000000;
          line-height: 32px;
          margin-top: 10px;
        }

        .coontactUs_list_item_tips {
          font-size: 18px;
          color: #5b5d5f;
          line-height: 28px;
          margin-top: 20px;
        }

        .coontactUs_list_item_qrcode {
          font-size: 28px;
          color: #000000;
          line-height: 32px;
          margin-top: 20px;
        }
      }
    }
  }
}

.font {
  height: 101px;
  border-top: 1px solid #e6e6e6;
  width: 1568px;
  margin: 0 auto;
  background: #fff;
  overflow: hidden;
  display: flex;
  .font_dom {
    z-index: 10;
    height: 100px;
    background: #fff;
    display: flex;
    align-items: center;
  }
  .font_for {
    display: flex;
  }
}
.swiper-wrapper {
  align-items: center;
}
::v-deep .swiper-slide-active {
  height: 90px !important;
  line-height: 90px !important;
  color: #157b2f !important;
}
::v-deep .swiper-slide-next {
  height: 80px;
  line-height: 80px;
  text-align: center;
  background: #ffffff;
  box-shadow: inset 0px 1px 33px 0px rgba(21, 123, 47, 0.2);
  border-radius: 13px;
  font-size: 24px;
  border: 1px solid #b5d6bd;
  opacity: 0.53;
}
::v-deep .swiper-slide-prev {
  height: 80px;
  line-height: 80px;
  text-align: center;
  background: #ffffff;
  box-shadow: inset 0px 1px 33px 0px rgba(21, 123, 47, 0.2);
  border-radius: 13px;
  font-size: 24px;
  border: 1px solid #b5d6bd;
  opacity: 0.53;
}
::v-deep .swiper-slide-active {
  text-align: center;
  background: #ffffff;
  box-shadow: inset 0px 1px 47px 0px rgba(21, 123, 47, 0.2);
  border-radius: 18px;
  border: 2px solid #b5d6bd;
  opacity: 1 !important;
}

@keyframes slide-left {
  from {
    transform: translateX(-30%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes slide-R {
  from {
    transform: translateX(30%);
  }
  to {
    transform: translateX(0%);
  }
}

::v-deep .animate-left {
  animation: slide-left 0.8s forwards; /* 动画持续时间和应用 */
}

::v-deep .animate-R {
  animation: slide-R 0.8s forwards; /* 动画持续时间和应用 */
}
// .size {
//   font-size: 26px !important;
// }
</style>
